// src/components/Footer.js
import React from 'react';
import { Box, Typography, IconButton, Grid, Divider } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
import { WhatsApp, YouTube } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#180161',
        color: 'white',
        p: 3,
        mt: 'auto',
        paddingTop: '80px'
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        {/* Tentang Kami Section */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>Tentang Kami</Typography>
          <Typography variant="body2">
            CV. Kausar Sukabumi
            Produsen Mesin Pencetak Pelet yang sudah beroperasi sejak 2010.
            Sudah melayani pelanggan di seluruh Indonesia dan sudah terdaftar di kementrian.
          </Typography>
        </Grid>

        {/* Kontak Kami Section */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>Lokasi Kami </Typography>
          <Typography variant="body2">
            {/* No. Hp : 081563235990 <br /> */}
            Alamat : Kp. Cipriangan RT 04 RW 06, Desa Semplak, Kecamatan Sukalarang, Kabupaten Sukabumi, Jawa Barat, 43191
          </Typography>
        </Grid>

        {/* Social Media Section */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>Ikuti Kami</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'left', mt: 1 }}>
            <IconButton color="inherit" onClick={() => window.open('https://www.instagram.com/cvkausarsukabumi', '_blank')}>
              <InstagramIcon />
            </IconButton>
            <IconButton color="inherit" onClick={() => window.open('https://youtube.com/@usniarie3451?si=Tae8lEJQGtJxt_QD', '_blank')}>
              <YouTube />
            </IconButton>
            <IconButton color="inherit" onClick={() => window.open('https://wa.me/628889624702', '_blank')}>
              <WhatsApp />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2, backgroundColor: 'rgba(255, 255, 255, 0.3)' }} />

      {/* Footer Copyright */}
      <Typography variant="body2" align="center">
        © {new Date().getFullYear()} CV Kausar. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
