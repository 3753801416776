// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline, Box } from '@mui/material';
import Header from './Header';
import Headeradmin from './Headeradmin'; // Import Headeradmin
import Dashboard from './components/Dashboard';
import InventoryManagement from './components/InventoryManagement';
import Purchase from './components/Purchase';
import Login from './auth/Login';
import AdminLogin from './auth/AdminLogin';
import Footer from './Footer';
import NotFound from './components/NotFounds';
import Cart from './components/Cart';
import SalesDetail from './components/SalesDetail';
import CreateUsers from './components/CreateUsers';
import About from './components/About';
import CrudAbout from './components/CrudAbout';
import InventoryForm from './components/InventoryForm';
import Ppn from './components/Ppn';
import Delivery from './components/Delivery';
import DocumentList from './components/admin/DocumentList';
import AddDocumentForm from './components/admin/AddDocumentForm';
import PhotoList from './components/admin/PhotoList';
import AddPhotoForm from './components/admin/AddPhotoForm';
import DashboardAdmin from './components/admin/DashbordAdmin';
import UserList from './components/admin/UsersList';
import CreateAdmin from './components/CreateAdmin';
import AdminList from './components/admin/AdminList';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#180161',
    },
    secondary: {
      main: '#f8f9fa',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

// Komponen ProtectedRoute untuk melindungi rute pengguna biasa
const ProtectedRoute = ({ element: Element, user, ...rest }) => {
  return user ? <Element {...rest} /> : <Navigate to="/login" />;
};

// Komponen AdminRoute untuk melindungi rute admin
const AdminRoute = ({ element: Element, admin, ...rest }) => {
  return admin ? <Element {...rest} /> : <Navigate to="/admin" />;
};

const App = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const admin = JSON.parse(localStorage.getItem('admin'));

  console.log(`ini user : ${JSON.stringify(user)}`);
  console.log(`ini admin : ${JSON.stringify(admin)}`);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh', // Ensures full height of viewport
        }}
      >
        <Router>

          {/* Tampilkan Headeradmin hanya jika admin login */}
          {admin ? <Headeradmin /> : <Header />} {/* Jika admin login, tampilkan Headeradmin, jika tidak tampilkan Header */}

          <Box sx={{ flex: 1 }}> {/* Flex 1 to grow and push footer down */}


            <Routes>
              {/* Rute untuk User Guest */}
              <Route path="/" element={<Dashboard />} />
              <Route path="/about" element={<About />} />
              <Route path="/create_user" element={<CreateUsers />} />
              <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
              <Route path="/cart" element={<ProtectedRoute element={Cart} user={user} />} />

              {/* Rute untuk User */}
              <Route path="/purchase/:id" element={<Purchase />} />
              <Route path="/salesdetail" element={<ProtectedRoute element={SalesDetail} user={user} />} />

              {/* Rute untuk Admin */}
              <Route path="/admin" element={admin ? <Navigate to="/admin/dashboard" /> : <AdminLogin />} />
              <Route path="/admin/dashboard" element={<AdminRoute element={DashboardAdmin} admin={admin} />} />
              <Route path="/admin/inventory" element={<AdminRoute element={InventoryManagement} admin={admin} />} />
              <Route path="/admin/inventory/add" element={<AdminRoute element={InventoryForm} admin={admin} />} />
              <Route path="/admin/about" element={<AdminRoute element={CrudAbout} admin={admin} />} />
              <Route path="/admin/ppn" element={<AdminRoute element={Ppn} admin={admin} />} />
              <Route path="/admin/delivery" element={<AdminRoute element={Delivery} admin={admin} />} />
              <Route path="/admin/document" element={<AdminRoute element={DocumentList} admin={admin} />} />
              <Route path="/admin/document/add" element={<AdminRoute element={AddDocumentForm} admin={admin} />} />
              <Route path="/admin/photo/" element={<AdminRoute element={PhotoList} admin={admin} />} />
              <Route path="/admin/users/" element={<AdminRoute element={UserList} admin={admin} />} />
              <Route path="/admin/list" element={<AdminRoute element={AdminList} admin={admin} />} />
              <Route path="/admin/add" element={<AdminRoute element={CreateAdmin} admin={admin} />} />
              <Route path="/admin/photo/add" element={<AdminRoute element={AddPhotoForm} admin={admin} />} />
              {/* Rute 404 Not Found */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>

          <Footer />
        </Router>
      </Box>
    </ThemeProvider>
  );
};

export default App;
