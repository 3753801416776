import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  Alert,
  Container,
  Paper,
  Grid
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL; // Adjusted for common environment variable names
const CreateAdmin = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    // username: "",
    password: "",
    email: "",
    // phone_number: "",
    // address: "",
    // gender: "",
    // birthdate: dayjs(),
    photo: null,
    // created_at: '',
    is_active: 1,
  });
  const navigate = useNavigate(); // Hook for navigation
  const [imagePreview, setImagePreview] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // "success" | "error" | "warning" | "info"

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      birthdate: date ? dayjs(date).format("YYYY-MM-DD") : null,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        photo: file,
      });

      // Update image preview
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    // Tambahkan data teks
    for (const key in formData) {
      if (key !== "photo") {
        formDataToSend.append(key, formData[key]);
      }
    }

    // Tambahkan data file (foto)
    if (formData.photo) {
      formDataToSend.append("photo", formData.photo);
    }

    try {
      const response = await fetch(`${apiUrl}api/admins/create`, {
        method: "POST",
        body: formDataToSend,
      });
      const result = await response.json();
      // Update notifikasi Snackbar
      setSnackbarSeverity("success");
      setSnackbarMessage(result.message);
      setSnackbarOpen(true);

      setTimeout(() => {
        navigate('/admin/');
      }, 2000);

    } catch (error) {
      console.error("Error:", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("An error occurred. Please try again.");
      setSnackbarOpen(true);
    }
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="sm" sx={{ paddingBottom: "200px" }}>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
        {/* Snackbar untuk Notifikasi */}

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000} // Durasi tampil notifikasi
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Typography variant="h5" gutterBottom>
          Tambah Admin Baru
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Nama Lengkap"
                name="fullname"
                value={formData.fullname}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                label="Username"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                autoComplete="off"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleInputChange}
                autoComplete="off"
                fullWidth
                required
              />
            </Grid>

            {/* <Grid item xs={12}>
              <TextField
                label="Nomer HP"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid> */}
            {/* <Grid item xs={12}>
              <TextField
                label="Alamat"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid> */}
            {/* <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Jenis Kelamin</InputLabel>
                <Select
                  label="Gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                >
                  <MenuItem value="Pria">Pria</MenuItem>
                  <MenuItem value="Wanita">Wanita</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            {/* <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Tanggal Lahir"
                  value={dayjs(formData.birthdate)}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid> */}
            <Grid item xs={12}>
              <Typography variant="body1">Foto Profil</Typography>
              <input
                type="file"
                className="form-control-file"
                name="photo"
                accept="image/*"
                onChange={handleFileChange}
              />
              {imagePreview && (
                <div style={{ marginTop: "10px" }}>
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        {/* <Typography sx={{ paddingTop: '10px' }}>Sudah punya akun? {'  '} */}
        <Button variant='outlined' sx={{ marginTop: 2 }} onClick={() => navigate("/admin/list")}>Kembali</Button>
        {/* </Typography> */}
      </Paper>
    </Container>
  );
};

export default CreateAdmin;
