import React, { useState } from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar, Alert,
  Typography,
} from '@mui/material';
import DataTable from 'react-data-table-component';
import ReactQuill from 'react-quill';
import axios from 'axios';
import 'react-quill/dist/quill.snow.css'; // Gaya bawaan editor

const apiUrl = process.env.REACT_APP_API_URL;

const InventoryList = ({ inventory, formatPrice, loadInventory }) => {
  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [deleteDialog, setDeleteDialog] = useState({ open: false, id: null });
  const handleClose = () => {
    setOpen(false);
    setCurrentItem(null);
  };
  // Fungsi untuk menghapus item
  const handleDeleteConfirmed = () => {
    const { id } = deleteDialog;

    axios
      .get(`${apiUrl}/api/stocklistinventory/delete/${id}`)
      .then((response) => {
        // Cek jika ada pesan sukses
        if (response.data.message) {
          console.log(`Result Delete: ${response.data.message}`);
          setSnackbar({
            open: true,
            message: response.data.message,
            severity: "success",
          });
          loadInventory(); // Refresh data setelah penghapusan
        } else {
          throw new Error("Unexpected response format");
        }
      })
      // .catch((error) => {
      //   console.error("Error deleting data:", error);
      //   setSnackbar({
      //     open: true,
      //     message: "Failed to delete item.",
      //     severity: "error",
      //   });
      // })
      .finally(() => {
        setDeleteDialog({ open: false, id: null }); // Tutup dialog
      });
  };


  // Buka modal untuk mengupdate item
  const handleUpdateClick = (item) => {
    setCurrentItem(item);
    setOpen(true);
  };


  const handleSave = async () => {
    const formData = new FormData();
    formData.append('brand', currentItem.brand);
    formData.append('specifications', currentItem.specifications);
    formData.append('dimensi', currentItem.dimensi);
    formData.append('weight', currentItem.weight);
    formData.append('stock_quantity', currentItem.stock_quantity);
    formData.append('unit_price', currentItem.unit_price);

    try {
      const response = await fetch(`${apiUrl}/api/stocklistinventory/update/${currentItem.id_sli}`, {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      if (result.message) {
        // Tampilkan notifikasi
        setSnackbar({ open: true, message: result.message, severity: "success" });
        handleClose(); // Menutup modal loadInventory(); // Refresh data setelah menyimpan
      }
    } catch (error) {
      console.error('Error updating data:', error);
      setSnackbar({ open: true, message: "Failed to save item.", severity: "error" });

    }

  };

  // Update state untuk input perubahan
  const handleChange = (value, fieldName) => {
    setCurrentItem((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };
  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: "", severity: "success" });
  };
  const columns = [
    {
      name: '#',
      cell: (row, index) => index + 1,
      width: '50px',
    },
    {
      name: 'Brand',
      selector: (row) => row.brand,
      sortable: true,
      width: '250px',
    },
    {
      name: 'Specifications',
      selector: (row) => row.specifications,
      width: '450px',
    },
    {
      name: 'Qty',
      selector: (row) => row.stock_quantity,
      width: '80px',
    },
    {
      name: 'Dimensi',
      selector: (row) => row.dimensi,
      width: '120px',
    },
    {
      name: 'Weight (Kg)',
      selector: (row) => row.weight,
      width: '120px',
    },
    {
      name: 'Unit Price',
      selector: (row) => formatPrice(row.unit_price),
      width: '150px',
    },
    {
      name: 'Actions',
      width: '250px',
      cell: (row) => (
        <Box display="flex" gap={1}>
          <Button variant="contained" color="warning" onClick={() => handleUpdateClick(row)}>
            Update
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setDeleteDialog({ open: true, id: row.id_sli })}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={inventory}
        pagination
        highlightOnHover
        striped
        responsive
        customStyles={{
          rows: {
            style: {
              fontSize: '14px',
            },
          },
          headCells: {
            style: {
              backgroundColor: '#f4f6f8',
              fontWeight: 'bold',
            },
          },
          cells: {
            style: {
              fontSize: '12px',
            },
          },
        }}
      />

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Update Item</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Brand"
            name="brand"
            fullWidth
            value={currentItem?.brand || ''}
            onChange={(e) => handleChange(e.target.value, 'brand')}
          />

          <Typography marginTop={2} marginBottom={1}>
            Specifications
          </Typography>
          <ReactQuill
            theme="snow"
            value={currentItem?.specifications || ''}
            onChange={(content) => handleChange(content, 'specifications')}
          />

          <TextField
            margin="dense"
            label="Dimensi"
            name="dimensi"
            fullWidth
            value={currentItem?.dimensi || ''}
            onChange={(e) => handleChange(e.target.value, 'dimensi')}
          />

          <TextField
            margin="dense"
            label="Weight (Kg)"
            name="weight"
            fullWidth
            type="number"
            value={currentItem?.weight || ''}
            onChange={(e) => handleChange(e.target.value, 'weight')}
          />

          <TextField
            margin="dense"
            label="Stock Quantity"
            name="stock_quantity"
            fullWidth
            type="number"
            value={currentItem?.stock_quantity || ''}
            onChange={(e) => handleChange(e.target.value, 'stock_quantity')}
          />

          <TextField
            margin="dense"
            label="Unit Price"
            name="unit_price"
            fullWidth
            type="number"
            value={currentItem?.unit_price || ''}
            onChange={(e) => handleChange(e.target.value, 'unit_price')}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} variant="outlined" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* 
       */}

      <Dialog open={deleteDialog.open} onClose={() => setDeleteDialog({ open: false, id: null })}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this item?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog({ open: false, id: null })} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmed} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/*  */}
      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default InventoryList;
